import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><h3 {...{
        "className": "specialstyling mt-6",
        "id": "crusades"
      }}><span parentName="h3" {...{
          "className": "category"
        }}>{`EVANGELISTIC CRUSADES`}</span></h3>{`
    `}<h4>{`SOWING THE SEEDS OF THE GOSPEL`}</h4>
      <p>{`
    As I write this, I’m preparing to preach the Gospel in Arizona in a few weeks, followed quickly by an outreach in Cochabamba, Bolivia, in April. We praise God for an incredible amount of enthusiasm and participation as both communities have been praying and working to proclaim the Good News in their regions. 
`}</p>
      <div {...{
        "className": "image-float-right"
      }}>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "499px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAYDBQL/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB7/O1nyseRlNBViP/xAAcEAACAgIDAAAAAAAAAAAAAAACAwATAQQUITL/2gAIAQEAAQUChur2I19Ucywx6E/LBxzMT//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABwQAAEEAwEAAAAAAAAAAAAAAAEAAhAREiEiQf/aAAgBAQAGPwJdO4qNtciR6gILa1nH/8QAHBABAAICAwEAAAAAAAAAAAAAAQARMUEhUWGh/9oACAEBAAE/IY9dWioYhclHYQn3C3B4BU0dofYnMKTCf//aAAwDAQACAAMAAAAQl8dD/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EMqv/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQETH/2gAIAQIBAT8QqFoj/8QAHBABAQACAwEBAAAAAAAAAAAAAREAITFBUXHh/9oACAEBAAE/EMQebKjqOtneIQjR7zmUZxH5+zF6EACJdBlwbKvYZtThb4jJgA0GtKXFrn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Will Graham preaching at pulpit",
            "title": "Will Graham preaching at pulpit",
            "src": "/static/13b988851eab4ef3f30dc7d8aec79fae/5cb26/will-graham-standing.jpg",
            "srcSet": ["/static/13b988851eab4ef3f30dc7d8aec79fae/f93b5/will-graham-standing.jpg 300w", "/static/13b988851eab4ef3f30dc7d8aec79fae/5cb26/will-graham-standing.jpg 499w"],
            "sizes": "(max-width: 499px) 100vw, 499px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
      </div>
      <p>{`
    In `}<strong parentName="p">{`Fountain Hills, Arizona`}</strong>{`—a suburb on the northeastern edge of the Phoenix metro area—more than 1,100 have taken the Christian Life and Witness Course, where they were encouraged in their faith and equipped to share that same hope with others. While we’re praying for a wonderful harvest at the `}<strong parentName="p">{`Look Up Celebration at The Fountain on March 22`}</strong>{`, we’re also excited that these local believers will continue to reach their neighbors for Christ long after our time in the region is done. 
`}</p>
      <p>{`
    `}<strong parentName="p">{`Cochabamba`}</strong>{` is the third-largest city in `}<strong parentName="p">{`Bolivia`}</strong>{`, with a population of more than 650,000 people. The enthusiasm we are seeing as they work in this bustling region is remarkable. More than 800 churches have partnered with us to proclaim the hope of Christ, and more than 500 buses are being secured to bring people to the event. We believe God is preparing to do something big there on April 5.
`}</p>
      <p>{`
    Will you pray for these two events? Evangelism is spiritual warfare, and I’m so grateful for friends like you who lift up me and the team as we carry the peace of Jesus into these areas.
`}</p>
      <p>{`
    Later this year, I’ll return to `}<strong parentName="p">{`Tallinn, Estonia`}</strong>{`. My grandfather preached there in 1984 during his series of outreaches behind the Iron Curtain, and my father returned 25 years later to reach a new generation. In late January, we held a special meeting to launch this effort, and we were blown away by the response. Approximately 500 pastors and believers attended, and we received the support of top evangelical leaders across the country. One denominational leader told us that my father’s 2009 outreach is continuing to have an impact in the country, with people still—to this day—coming to Christ as a result. 
`}</p>
      <p>{`
    `}<strong parentName="p">{`Will you pray with us that God will bring another harvest in Estonia?`}</strong>{`
`}</p></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      