import { ButtonCollapsible } from '@bgea-js/design-library';
import { SingleDonation as SingleDonationForm } from '@bgea-js/form-templates';
import MainContentApr from "../../../../src/content/prayer-letter-april/main-content-april.md";
import LearnMoreApr from "../../../../src/content/prayer-letter-april/learn-more-details-april.md";
import "../../../../src/content/prayer-letter-april/prayer-letter-april-2025.scss";
import * as React from 'react';
export default {
  ButtonCollapsible,
  SingleDonationForm,
  MainContentApr,
  LearnMoreApr,
  React
};