import React from 'react';
import { Block } from '@bgea-js/react-ui';

const customConfirmationEtResponse = () => (
  <div>
    <Block>
      <h1 className="title">እናመሰግናለን!</h1>
      <div className="block">
        <p>ያስገቡት መረጃ ተጠናቋል።</p>
        <p>በዮሐንስ ወንጌል ላይ የተመሠረተ ለአዲስ ክርስቲያኖች የሚሆን የግል የመጽሐፍ ቅዱስ ጥናት የሆነውን “በክርስቶስ መኖር” የተሰኘውን ነፃ ቅጂዎን ያውርዱ። </p>
        <p>በሁለት ክፍል በpdf የተዘጋጀውን ጽሑፍ ለማግኘት/ለማውረድ ፦<br/>
         <a href="/resources/part1_amharic_lic.pdf" class="link" target="_blank">ክፍል አንድ</a><br/>
         <a href="/resources/part2_amharic_lic.pdf" class="link" target="_blank">ክፍል ሁለት</a>&nbsp;
    የሚለውን ይጫኑ።
      </p>
      </div>
    </Block>
  </div>
);

export default customConfirmationEtResponse;
