import React from 'react';

const donationHeaderHCBO = ({ firstName }) => (
  <div>
    <h2>
      Good News! {firstName}, your copy of <em>Hidden Christmas</em> should arrive in 2–4 weeks.
    </h2>
    <p>We praise God for your heart for His truth, and we hope this book will fill you with <em>“righteousness and peace and joy in the Holy Spirit”</em> (Romans 14:17, NASB).</p>
    <div>
      <p>
        We are praying that this free resource will encourage you and your
        family in the days ahead.
      </p>
      <p>
        <b>Before you go, there are a few things we’d like you to know …</b>
      </p>
      <p>
        The Billy Graham Evangelistic Association (BGEA) is able to offer
        helpful resources like the one you’re receiving because of friends who
        generously make this ministry possible. The Lord is using the gifts and
        prayers of believers—like you—to reach people across the globe with His
        truth and love, especially at a time when so many are experiencing
        uncertainty and fear. Will you help them hear the Good News of God’s
        love?
      </p>
      <blockquote
        style={{
          borderLeft: '1px solid #6e6e6e',
          borderRight: '1px solid #6e6e6e',
          textAlign: 'center',
          marginBottom: 0,
          color: '#6e6e6e',
          padding: '9px 19px',
        }}
      >
        Your gift today will help share <em>“the everlasting gospel”</em>{' '}
        (Revelation 14:6, NKJV) with people around the world who are looking for
        hope—and equip them with powerful Biblical resources to become faithful
        followers of Jesus Christ.
      </blockquote>
    </div>
  </div>
);

export default donationHeaderHCBO;
