import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
    `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "rrt"
      }}>{`
      `}<span parentName="h3" {...{
          "className": "category"
        }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span>{`
    `}</h3>{`
    `}<h4 parentName="div">{`CHAPLAINS MINISTER IN CALIFORNIA AND NEW ORLEANS`}</h4>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
      The images of complete devastation from the wildfires burning out of control in California 
    are heartbreaking. Thousands of people have been forced from their homes, and firefighters 
    have been working around the clock. Our crisis-trained Billy Graham Rapid Response Team (BG-RRT) 
    chaplains deployed to point hurting people to the hope of Jesus Christ.
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     Miles’* home was completely destroyed by the fire, along with his belongings. A chaplain 
     `}<div parentName="div" {...{
          "className": "image-float-left"
        }}>{`
      `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/2gAMAwEAAhADEAAAARcWhRmNn//EABsQAAICAwEAAAAAAAAAAAAAAAECABEDEiEx/9oACAEBAAEFAhk5vYLKZ5EUU3J//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bh//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAgEBPwGsf//EABYQAAMAAAAAAAAAAAAAAAAAAAEQIP/aAAgBAQAGPwKCv//EABsQAAMBAAMBAAAAAAAAAAAAAAABESFBUXGh/9oACAEBAAE/IYbWTMPer0Oa38Gg1xCVEf/aAAwDAQACAAMAAAAQ9y//xAAYEQACAwAAAAAAAAAAAAAAAAAAASFBUf/aAAgBAwEBPxBJg4o//8QAGBEBAAMBAAAAAAAAAAAAAAAAAQARIUH/2gAIAQIBAT8Qe7gMvZ//xAAbEAEBAAMAAwAAAAAAAAAAAAABEQAhMWFxof/aAAgBAQABPxCSRSBV93FhEBLYbgU1kONYBkPEpPuLgI4F5rNjaCZ//9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "rrt chaplains police palisades ed1 9257",
              "title": "rrt chaplains police palisades ed1 9257",
              "src": "/static/e6c8164f894cfc9dfb4aa0f5586ba573/6a068/rrt-chaplains_police_palisades_ed1_9257.jpg",
              "srcSet": ["/static/e6c8164f894cfc9dfb4aa0f5586ba573/f93b5/rrt-chaplains_police_palisades_ed1_9257.jpg 300w", "/static/e6c8164f894cfc9dfb4aa0f5586ba573/b4294/rrt-chaplains_police_palisades_ed1_9257.jpg 600w", "/static/e6c8164f894cfc9dfb4aa0f5586ba573/6a068/rrt-chaplains_police_palisades_ed1_9257.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
      `}<figcaption parentName="div">{`
      `}<strong parentName="figcaption">{`WILDFIRES IN SOUTHERN CALIFORNIA `}</strong>{`have already destroyed thousands of homes, businesses,
       and other structures. Chaplains are offering the comfort of Jesus Christ to evacuees in the region. 
    `}</figcaption>{`
    `}</div>{`
    met Miles 
    and their conversation quickly turned to spiritual matters. Miles described himself as a Baptist, 
    but it quickly became clear he did not have a personal relationship with Jesus Christ. When our chaplain 
    asked about eternity, `}<strong parentName="div">{`Miles responded he was hoping his good works would earn him a spot in Heaven.`}</strong>{` He 
    listened closely as our chaplain shared the truth of the Gospel. After a few minutes, Miles prayed to 
    receive Jesus Christ in repentance and faith. When our chaplains gave him a new Bible, he asked them to 
    inscribe “Born Again” in the front page.
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
   Our chaplains also ministered in New Orleans. Just three hours into the new year, at least 14 people lost
   their lives in a senseless terror attack. `}<strong parentName="div">{`Sin and the evil of the human heart are behind this tragedy—and 
   Jesus Christ is the only answer.`}</strong>{` Our crisis-trained chaplains deployed on New Year’s Day to share His promise 
   that “`}<em parentName="div">{`in Me you may have peace. In the world you will have tribulation; but be of good cheer, I have overcome 
   the world`}</em>{`” (John 16:33, NKJV). 
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
       A couple of chaplains met Noah* at a memorial on Bourbon Street, near where 
      `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
      `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAMC/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQD/2gAMAwEAAhADEAAAAZQ3FtBP/8QAGhABAAIDAQAAAAAAAAAAAAAAAgASAxFBIv/aAAgBAQABBQK5iJptiZD75z//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8B1Ib/xAAXEQEBAQEAAAAAAAAAAAAAAAABACJh/9oACAECAQE/AcrJy//EABsQAAIBBQAAAAAAAAAAAAAAAAABAhAREiGB/9oACAEBAAY/AneAzGK0cJKn/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARUTEhcf/aAAgBAQABPyESgCe/Imjl5AoBHHYAjRLcMChP/9oADAMBAAIAAwAAABDjH//EABcRAAMBAAAAAAAAAAAAAAAAAAABETH/2gAIAQMBAT8QgYiaz//EABcRAQEBAQAAAAAAAAAAAAAAAAERAFH/2gAIAQIBAT8QAEOaCkb/xAAdEAEAAwACAwEAAAAAAAAAAAABABEhMaFBUXHR/9oACAEBAAE/EAQrsLq4/JQtEb9ny5NWZBv1zL06xQq9qAWGlPZCyXe9z//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "rrt chaplains n orleans 250104 1653 ",
              "title": "rrt chaplains n orleans 250104 1653 ",
              "src": "/static/d76eae8df80d0d3f17c53b22da6a64bf/6a068/rrt-chaplains_n.orleans_250104_1653_.jpg",
              "srcSet": ["/static/d76eae8df80d0d3f17c53b22da6a64bf/f93b5/rrt-chaplains_n.orleans_250104_1653_.jpg 300w", "/static/d76eae8df80d0d3f17c53b22da6a64bf/b4294/rrt-chaplains_n.orleans_250104_1653_.jpg 600w", "/static/d76eae8df80d0d3f17c53b22da6a64bf/6a068/rrt-chaplains_n.orleans_250104_1653_.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
      `}</div>{`
      the attack took place. The young 
      man was obviously shaken. He quietly told chaplains that the tragedy had stolen his peace and made him confront 
      his own mortality. This opened the door for our chaplains 
      to share the Gospel using our “Steps to Peace With God”
      booklet. Noah followed along and admitted he knew he was a sinner. After a few minutes, 
      `}<strong parentName="div">{`he eagerly prayed out loud to receive Jesus Christ in repentance and faith!`}</strong>{` We praise God for this new brother. 
  `}</div>{`
    `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
      Please pray for all those who are grieving in the aftermath of tragedy in New Orleans and California. 
    `}</div>{`
  `}<div parentName="div">{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABAv/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHT9iLZFmP/xAAaEAACAgMAAAAAAAAAAAAAAAAAAQISESEx/9oACAEBAAEFArMykdHslNl5I//EABcRAQADAAAAAAAAAAAAAAAAAAECEBH/2gAIAQMBAT8BYgbX/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/AR2f/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAERIRAxQf/aAAgBAQAGPwLjKRojFOD/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMUGR/9oACAEBAAE/IVsqjqw3HRmxfXwsRyrMib2qfJWL4//aAAwDAQACAAMAAAAQDD//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFR/9oACAEDAQE/EKkVYf/EABcRAAMBAAAAAAAAAAAAAAAAAAABEVH/2gAIAQIBAT8QqI9P/8QAGxABAQEBAQADAAAAAAAAAAAAAREhAEExUWH/2gAIAQEAAT8QDlKipSO8UAaNa+3nQ7z4wfmdRUjYdEeZQDGCV9eIAEqHr99//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Couples praying in Alaska",
            "title": "Couples praying in Alaska",
            "src": "/static/a467ee8b5ea293c486fdce34729ec185/6a068/rrt-n.orleans_250104_2479.jpg",
            "srcSet": ["/static/a467ee8b5ea293c486fdce34729ec185/f93b5/rrt-n.orleans_250104_2479.jpg 300w", "/static/a467ee8b5ea293c486fdce34729ec185/b4294/rrt-n.orleans_250104_2479.jpg 600w", "/static/a467ee8b5ea293c486fdce34729ec185/6a068/rrt-n.orleans_250104_2479.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div">{`
      `}<strong parentName="figcaption">{`THE HORRIFIC NEW YEAR’S ATTACK `}</strong>{`that killed at least 14 people in New Orleans left 
      many reeling with unfathomable loss and heartache. BG-RRT chaplains deployed to offer hope in Jesus’ Name.
    `}</figcaption>{`
  `}</div>{`
   `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
      `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "400px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDBQT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB7dx5E6DFLrJVA//EABwQAAICAgMAAAAAAAAAAAAAAAECACEDERASE//aAAgBAQABBQItYO+HFO/mMTd0mhBU/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFxAAAwEAAAAAAAAAAAAAAAAAABARMf/aAAgBAQAGPwJ0pVi//8QAGxABAQEAAgMAAAAAAAAAAAAAAREAEEFRYYH/2gAIAQEAAT8hj6aDga7ZSkb5coOfOFiIwBAhv//aAAwDAQACAAMAAAAQFC8A/8QAGBEAAgMAAAAAAAAAAAAAAAAAETEAARD/2gAIAQMBAT8QomJ7/8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAEP/aAAgBAgEBPxBYGGlO/wD/xAAcEAEBAAICAwAAAAAAAAAAAAABEQBBITEQUZH/2gAIAQEAAT8QaYEMXOYkTXiEE2TeDNrSQ9dfcFkqpHTEEjyYWUDoTrBIg0Z//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "africa map addis ababa",
            "title": "africa map addis ababa",
            "src": "/static/2f69284682a0ced7654b60d9ca3bb219/066f9/africa-map_addis-ababa.jpg",
            "srcSet": ["/static/2f69284682a0ced7654b60d9ca3bb219/f93b5/africa-map_addis-ababa.jpg 300w", "/static/2f69284682a0ced7654b60d9ca3bb219/066f9/africa-map_addis-ababa.jpg 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}</div>{`
   `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "rrt"
      }}>{`
      `}<span parentName="h3" {...{
          "className": "category"
        }}>{`EVANGELISTIC CRUSADES`}</span>{`
    `}</h3>{`
    `}<h4 parentName="div">{`THE LIGHT OF CHRIST IN ETHIOPIA`}</h4>{`
    `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     I’m thrilled that `}<strong parentName="div">{`God has opened the door for me to proclaim His Good News in Addis Ababa, the capital city of Ethiopia.`}</strong>{` I’ve been working to help people in this nation in Jesus’ Name for more than 35 years, and now I look forward to preaching His Gospel there in just a couple of weeks. This country boasts one of the oldest Christian communities in the world—but there are still thousands of people living there who do not know Jesus Christ. Addis Ababa is one of Africa’s most diverse and densely populated cities, and in the past two years, it is has seen a huge influx of refugees fleeing violence in neighboring Somalia and Sudan. 
    `}</div>{`
    `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     More than 1,500 churches in the area have been praying and working for many months to prepare for the Encountering God Festival scheduled for March 8 and 9. Thousands of local believers are participating in our I Am Andrew program by praying for unsaved loved ones and inviting them to the events. Local leaders have been hosting Christian Life and Witness Course (CLWC) classes, and to date, over 25,000 men and women have attended this evangelism and discipleship training. We praise God for this response. Thousands of them have signed up to serve as prayer counselors during the Festival. 
    `}</div>{`
    `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     “We have seen how CLWC training is closely connected to life. `}<strong parentName="div">{`We have learned from the training … that our lives should have an impact beyond our words,” `}</strong>{`shared one of the pastors who 
    is leading CLWC at his church. “To be honest, it has been a great inspiration to spread the Gospel of the Lord among our friends.”
    `}</div>{`
    `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     Will you pray for this evangelistic outreach? `}<strong parentName="div">{`Ask God to embolden local Christians to invite people far from Him to the Festival—and for the Holy Spirit to call men, women, and children to Himself.`}</strong>{` Even though religious freedom is strongly protected by the Ethiopian government, those who commit their lives to Christ and leave the old way of life can face severe backlash from their loved ones and neighbors. Please pray that the Lord will protect those who make decisions for Jesus Christ.
    `}</div>{`
    `}<h4 parentName="div">{`YOUR PRAYERS ARE VITAL`}</h4>{`
    `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     My son Will is scheduled to share the Gospel next month in `}<strong parentName="div">{`Fountain Hills, Arizona,`}</strong>{` a large suburb outside of Phoenix. More than a third of people in Arizona claim no faith whatsoever. I’m so thankful God has given Will the opportunity to urge men, women, and children there to turn their hearts “`}<em parentName="div">{`to the living God, who made the heaven, the earth, the sea, and all things that are in them`}</em>{`” (Acts 14:15, NKJV). `}<strong parentName="div">{`Ask the Lord to draw many people to Himself through this Celebration.`}</strong>{` 
    `}</div>{`
    `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
      Thank you for your heart to reach people far from God with His love. Your gifts and prayers make it possible to boldly proclaim His truth in every corner of our world.
    `}</div>{`
    `}<br parentName="div"></br>{`
      `}<p parentName="div">{`May God richly bless you,`}</p>{`
      `}<img parentName="div" {...{
        "className": "signature",
        "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
        "alt": ""
      }}></img>{`
  `}<p parentName="div">{`Franklin Graham`}</p>{`
  `}<p parentName="div">{`President & CEO`}</p>{`
  `}<p parentName="div">{`*Names and photos changed to protect privacy`}</p>{`
  `}<hr parentName="div"></hr>{`
  `}<div parentName="div" {...{
        "className": "give-background",
        "id": "january-give"
      }}>{`
    `}<h4 parentName="div" {...{
          "className": "cta projects"
        }}>{`
      `}<span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE
    `}</h4>{`
    `}<h4 parentName="div" {...{
          "className": "mb-6 smaller"
        }}>{`
      GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
      GOSPEL.
    `}</h4>{`
    `}<p parentName="div">{`
      `}<span parentName="p" {...{
            "className": "options"
          }}>{`WHERE MOST NEEDED`}</span>{` | The Gospel changes
      lives—now and for eternity. When you designate a gift for
      `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate
      ministry opportunities and needs, including the personnel, resources,
      materials, equipment, and support services vital to the work of evangelism
      around the world.
    `}</p>{`
     `}<p parentName="div">{`
      `}<span parentName="p" {...{
            "className": "options"
          }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span>{` | Your contribution 
      in support of the `}<strong parentName="p">{`Billy Graham Rapid Response Team`}</strong>{` helps make it possible for crisis-trained 
      chaplains to bring comfort in Christ’s Name to hurting communities and broken lives.
    `}</p>{`
    `}<p parentName="div">{`
      `}<span parentName="p" {...{
            "className": "options"
          }}>{`EVANGELISTIC CRUSADES`}</span>{` | So many people
      are living without the hope that comes from Jesus Christ. When you send a
      gift for `}<strong parentName="p">{`Evangelistic Crusades`}</strong>{`, you give people across
      the globe the chance to hear the Gospel at events like the upcoming
      Franklin Graham Festival in Ethiopia and Will Graham Celebration in
      Arizona.
    `}</p>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      