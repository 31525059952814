import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
    `}<span parentName="div" {...{
        "className": "image-float-left-first"
      }}>{`
        `}<span parentName="span" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "400px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAABAAFAQP/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABP7BaXRoMqHcRyrTjf//EAB0QAQACAgIDAAAAAAAAAAAAAAECEQMEEBIhMUL/2gAIAQEAAQUC9uTH1CZUpedpoi2fWyvaNhz/AP/EABcRAQEBAQAAAAAAAAAAAAAAAAAREgH/2gAIAQMBAT8BvFZZf//EABYRAQEBAAAAAAAAAAAAAAAAAAAREv/aAAgBAgEBPwGIrT//xAAbEAABBAMAAAAAAAAAAAAAAAABABARMSBRYf/aAAgBAQAGPwIdVtCEbLhVh//EABsQAAICAwEAAAAAAAAAAAAAAAABESEQQZEx/9oACAEBAAE/IegWVrgiJdjNIvdv0FT10tvQwhJ0h4EuCNY//9oADAMBAAIAAwAAABAQBbP/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAEDAQE/EHZhJHZvYydv/8QAGBEBAQEBAQAAAAAAAAAAAAAAAQARMUH/2gAIAQIBAT8QFkgZ5Z15f//EAB4QAQADAAICAwAAAAAAAAAAAAEAESExQRBhUZHh/9oACAEBAAE/EDEoUHNGsBgj3AV9LMVFNAZTjBz3FHuVlwAr1CeTdJSOU+Ss9xmgVwvV/JZXL8/F5Rn/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Will Graham",
            "title": "Will Graham",
            "src": "/static/820ad304b3bef8eafa1c15343ca7f6dd/066f9/will-graham.jpg",
            "srcSet": ["/static/820ad304b3bef8eafa1c15343ca7f6dd/f93b5/will-graham.jpg 300w", "/static/820ad304b3bef8eafa1c15343ca7f6dd/066f9/will-graham.jpg 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}</span>{`
    `}<p parentName="div">{`Dear Friend,`}</p>{`
    `}<p parentName="div">{`
        `}<strong parentName="p">{`Thank you for your partnership in sharing Jesus Christ,`}</strong>{` `}<em parentName="p">{`“the light of the world”`}</em>{` (John 8:12, NKJV), with people living in darkness. You are playing a major role in helping many find eternal hope, and we’re grateful for you!
    `}</p>{`
    `}<p parentName="div">{`
        There’s a great hunger for hope today in what was once a major center for the Gospel. After decades of increasing secularism, `}<strong parentName="p">{`Europe’s spiritual ground is parched and dry.`}</strong>{`
    `}</p>{`
    `}<p parentName="div">{`
        So many of its state-sponsored churches are dead. Many magnificent cathedrals—once places of worship—are now mostly tourist destinations. In the name of inclusion and diversity, governments are censoring their own people and restricting the freedom to proclaim the Name of Jesus. 
    `}</p>{`
    `}<p parentName="div">{`
        For the next few years, we are focusing on Europe. We want to fan the flames of evangelism there, igniting a passion for the lost and a boldness for the Gospel.
    `}</p>{`
    `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
        `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAEFA//EABQBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAZbdMOEaDH//xAAaEAEAAwADAAAAAAAAAAAAAAABAgMREyEx/9oACAEBAAEFAqcHgkiIwclV5b3b/8QAFxEBAAMAAAAAAAAAAAAAAAAAAQIQEf/aAAgBAwEBPwEjpX//xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQIBAT8BXJ//xAAZEAADAAMAAAAAAAAAAAAAAAAAARECEHH/2gAIAQEABj8CrVKiPTMun//EABsQAQACAgMAAAAAAAAAAAAAAAEAESExYXGB/9oACAEBAAE/IRwIqCQnVx2FJuYreWBaGvI7Mf/aAAwDAQACAAMAAAAQiN//xAAXEQEBAQEAAAAAAAAAAAAAAAABEQAx/9oACAEDAQE/EGadyRm//8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAhMf/aAAgBAgEBPxAULyNL/8QAHBABAAMBAQADAAAAAAAAAAAAAQARITFBYYGR/9oACAEBAAE/EAepYpfTuyjEF3oPIi5dB8nOUPs6SmXQv6iqANjPjJ//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Billy Graham preaching at pulpit",
            "title": "Billy Graham preaching at pulpit",
            "src": "/static/71f0c7a93361b42eb672c7b26382a67e/6a068/billy-graham.jpg",
            "srcSet": ["/static/71f0c7a93361b42eb672c7b26382a67e/f93b5/billy-graham.jpg 300w", "/static/71f0c7a93361b42eb672c7b26382a67e/b4294/billy-graham.jpg 600w", "/static/71f0c7a93361b42eb672c7b26382a67e/6a068/billy-graham.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
        `}<figcaption parentName="div">{`Billy Graham preaches to leaders gathered at the 1983 Amsterdam International Conference for Itinerant Evangelists.`}</figcaption>{`
    `}</div>{`
    `}<p parentName="div">{`
        Despite the challenges in this part of the world, we will not stay silent. My father is hosting the `}<strong parentName="p">{`European Congress on Evangelism, May 27–30, in Berlin.`}</strong>{` We’ve invited `}<strong parentName="p">{`1,000 pastors and believers from 57 countries and territories`}</strong>{` and prayerfully anticipate that it will be the most representative gathering of Christian leaders there in 25 years.
    `}</p>{`
    `}<p parentName="div">{`
        My grandfather Billy Graham had a special burden for Europe. In 1966, he convened a congress for church leaders in Berlin to “ask God to rekindle the flame of revival and evangelism throughout the world,” as he said in his opening address then. In the coming decades—in addition to Crusades all across the continent—he organized several more conferences on evangelism in Switzerland and the Netherlands to equip believers for the work of the Gospel. Now we need to do it again, for this generation.
    `}</p>{`
    `}<p parentName="div">{`
       Some leaders in Europe have toiled for years without seeing much fruit. Most need encouragement and a fresh anointing in their calling. 
    `}</p>{`
    `}<p parentName="div">{`
        During four days of worship, fellowship, and Biblical teaching in 10 languages, these faithful servants will study topics including discipleship, the Holy Spirit, prayer, unity, preaching, and giving an evangelistic invitation.
    `}</p>{`
    `}<p parentName="div">{`
        Will you join me in praying for these faithful followers of Christ? `}<strong parentName="p">{`I pray this congress will change Europe’s spiritual landscape—and sweep across the globe.`}</strong>{` The cost to send one leader to the gathering is $950—and what a strategic investment that is! If God leads you, will you consider a gift of any amount to underwrite the cost to equip one pastor or evangelist? The need is great, but we serve a great God!
    `}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      