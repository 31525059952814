import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>
  <p>Dear Friend,</p>
  <div className="paragraph-content">
    &emsp;Just a few days ago, I had the privilege of offering the invocation at President Donald J. 
    Trump’s second inauguration in Washington, D.C. I thanked God for protecting his life and 
    recalled the words of the Prophet Daniel as I asked the Lord to grant him wisdom: “<em>Blessed 
    be the name of God forever and ever, for wisdom and might are His. And He changes the times 
    and the seasons; He removes kings and raises up kings; He gives wisdom to the wise</em>” (Daniel 2:20–21, NKJV).
  </div>
    <div className="paragraph-content">
      &emsp;<strong>It was an honor to lift up the Name of Jesus Christ and point people to the King of kings and the Lord 
      of lords. From the Capitol rotunda, I reminded our nation that we will only be great if we keep our eyes fixed on Him.</strong>
    </div>
    <div className="paragraph-content">
      Will you pray each day for President Trump, his Cabinet, and all those in authority? <strong>The Bible assures us that our prayers 
      matter, and God commands us to pray for our leaders.</strong>
    </div>
    <div className="paragraph-content">
      &emsp;
        My son Will also had the chance to lift up the leaders of our home state, North Carolina, in prayer. Just a couple of 
        weeks ago, he had the honor of opening the General Assembly’s first session of 2025. He prayed that these men and women 
        would have the wisdom to know what is right and the courage to stand for truth.
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      