import { InquirerResponse as InquirerResponseFormCochabamba } from '@bgea-js/form-templates';
import "../../../../src/content/inquirer-response-page-cochabamba/inquirer-cochabamba-styles.scss";
import privacyPolicyCopy from "../../../../src/content/inquirer-response-page-cochabamba/privacyPolicyCopy";
import inquirerConfirmation from "../../../../src/content/inquirer-response-page-cochabamba/inquirerConfirmation";
import * as React from 'react';
export default {
  InquirerResponseFormCochabamba,
  privacyPolicyCopy,
  inquirerConfirmation,
  React
};