import React from 'react';

const privacyPolicyCopy = () => (
  <div>
    <p>
      Los datos personales que BGEA recabará, serán utilizados para
      mantenerlo informado sobre Esperanza Cochabamba con Will Graham,
      así como de otras actividades ministeriales de BGEA, usando dichos
      datos para gestionar su participación con BGEA. Así mismo, sus
      datos podrán ser compartidos con las iglesias locales para que
      puedan contactarlo. Todos sus datos personales quedarán en
      custodia de BGEA en Estados Unidos (y podrán ser transmitidos a
      nuestros socios ministeriales en su país para su procesamiento)
      únicamente para los fines arriba establecidos. Así mismo podrá
      acceder a nuestra política de privacidad en:
      <a
        href="https://es.billygraham.org/privacidad/"
        target="_blank"
        rel="noreferrer"
      >
        {' '}
        BillyGraham.org/privacidad.
      </a>
      .
    </p>
  </div>
)

export default privacyPolicyCopy;
