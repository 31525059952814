import React from 'react';
import { Block } from '@bgea-js/react-ui';

const privacyPolicyCopyVietnam = () => (
  <div className="vietnam-privacy-policy">
<Block>
<p><strong>Tuyên bố Cho phép Sử dụng Dữ liệu</strong></p>

  <p className='privacy'>
    Qua việc cung cấp thông tin cá nhân của mình (bao gồm cả quan điểm tôn giáo), bạn đồng ý với cho chúng tôi: sử dụng thông tin của bạn để giữ liên lạc về (các) sự kiện và thông báo cho bạn biết về các hoạt động mục vụ khác của BGEA; và sử dụng những thông tin này để ghi nhận sự tham gia của bạn với BGEA. Những chi tiết này sẽ được lưu giữ bởi
    BGEA ở Hoa Kỳ và/hoặc quốc gia nơi chúng được thu thập (và có thể được chuyển cho các đối tác mục vụ của chúng tôi ở quốc gia của bạn để xử lý dữ liệu) chỉ cho các mục đích trên. Để quản lý những tùy chọn và tìm hiểu thêm về cách chúng tôi xử lý thông tin của bạn, hãy xem chính sách quyền riêng tư của BGEA tại
    <span className="privacypolicy"><a href="https://billygraham.org/vi/chinh-sach-bao-mat">&nbsp;Chính sách bảo mật</a></span>

  </p>
 
</Block>
</div>
);

export default privacyPolicyCopyVietnam;
