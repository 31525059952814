import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<p parentName="div">{`Dear Friend,`}</p>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
      I have just returned from Addis Ababa, Ethiopia’s capital city, where
    we saw God move in amazing ways during the two-day Encountering God
    evangelistic outreach. The Crusade was held March 8–9, exactly 65 years
    after my father preached there.
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     
    `}<strong parentName="div">{`
      In the nation’s largest outdoor public space, 117,000 people gathered the
      first night followed by 320,000 on the final evening—all to hear the
      Gospel of Jesus Christ. We give God all the glory for the thousands who
      surrendered their lives to Him.
    `}</strong>{`
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
      `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFpGhNNAiJ//8QAGxAAAwACAwAAAAAAAAAAAAAAAQIDERIhIiP/2gAIAQEAAQUCydXr3FTh7PqG5gfP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBABAAMBAAAAAAAAAAAAAAAAAQAQIjH/2gAIAQEABj8C7Ap0wr//xAAbEAEAAwADAQAAAAAAAAAAAAABABExIUFRYf/aAAgBAQABPyHmauc3qzsjuj9gQArIyiRMVvk//9oADAMBAAIAAwAAABBs/wD/xAAVEQEBAAAAAAAAAAAAAAAAAAAQYf/aAAgBAwEBPxCn/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxBLYyH/xAAcEAEBAAMAAwEAAAAAAAAAAAABEQAhMVFhcaH/2gAIAQEAAT8QZrAREq/MjJBsDR985YPXqt/cC+NEOwvL4wnS7H33uHBArtZ//9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "april fg speaking",
              "title": "april fg speaking",
              "src": "/static/95d64d7e9349e344225818605c52ccfd/e5166/april_fg_speaking.jpg",
              "srcSet": ["/static/95d64d7e9349e344225818605c52ccfd/f93b5/april_fg_speaking.jpg 300w", "/static/95d64d7e9349e344225818605c52ccfd/b4294/april_fg_speaking.jpg 600w", "/static/95d64d7e9349e344225818605c52ccfd/e5166/april_fg_speaking.jpg 1200w", "/static/95d64d7e9349e344225818605c52ccfd/d9c39/april_fg_speaking.jpg 1800w", "/static/95d64d7e9349e344225818605c52ccfd/6687f/april_fg_speaking.jpg 1966w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
      `}<figcaption parentName="div" {...{
            "className": "category"
          }}>{`
        `}<strong parentName="figcaption">{`FRANKLIN GRAHAM `}</strong>{` preaches the Gospel in Addis Ababa,
        the capital of Ethiopia.
      `}</figcaption>{`
    `}</div>{`
    We are `}<em parentName="div">{`“abounding through many thanksgivings to God”`}</em>{` `}<nobr parentName="div">{`(2 Corinthians
    9:12, NKJV)`}</nobr>{` for what He did in the Horn of Africa, and I’m eager to share
    some of the stories with friends like you who made this possible.
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
      Even before the first night, the Lord was calling men, women, and
    children to Himself. Tens of thousands of believers participated in our
    Christian Life and Witness Course (CLWC). One woman was inspired by the
    class to pray for a friend at work and her landlord, neither of whom knew
    Jesus Christ.
    `}<strong parentName="div">{`
      She lifted them up in prayer and then used what she learned at CLWC to
      share His love with them.
    `}</strong>{`
    We praise God that each of these individuals decided to commit their lives
    to Him—and her story is just one of many!
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      