import "../../../../src/content/esperanza-cochabamba/cochabamba.scss";
import { GetInvolved } from '@bgea-js/form-templates';
import iiaCopyBo from "../../../../src/content/esperanza-cochabamba/iiaCopy";
import cbConfirmation from "../../../../src/content/esperanza-cochabamba/cbConfirmation";
import privacyPolicyCochabamba from "../../../../src/content/esperanza-cochabamba/privacyPolicyCochabamba";
import * as React from 'react';
export default {
  GetInvolved,
  iiaCopyBo,
  cbConfirmation,
  privacyPolicyCochabamba,
  React
};