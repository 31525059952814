import React from 'react';

const inquirerConfirmation = () => (
  <div>
    <h1 className="title">¡Gracias!</h1>
    <div className="block">
      <p>Tu información fue recibida exitosamente.</p>
    </div>
  </div>
);

export default inquirerConfirmation;