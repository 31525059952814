import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div>{`
  `}<div parentName="div">{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABYZjkuJjT/8QAGRAAAwADAAAAAAAAAAAAAAAAAAECAxET/9oACAEBAAEFAnlZ0tnYtmOtjp7/AP/EABcRAQADAAAAAAAAAAAAAAAAAAABEUH/2gAIAQMBAT8B1cP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAERL/2gAIAQIBAT8BjL//xAAYEAADAQEAAAAAAAAAAAAAAAAAESExEP/aAAgBAQAGPwIbw3iJD//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQWFRgZH/2gAIAQEAAT8hC2Lb3NAlUtz9xd+o78cX3EPDKf/aAAwDAQACAAMAAAAQzD//xAAWEQEBAQAAAAAAAAAAAAAAAAARAAH/2gAIAQMBAT8QzUEV/8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBITH/2gAIAQIBAT8Qw3Y0n//EABkQAQEBAQEBAAAAAAAAAAAAAAERIQBRYf/aAAgBAQABPxA+G0ArPp50Lhlun3h10brR1g4vrlqJFLq95xAVSWvvf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Ethiopia crowd worships",
            "title": "Ethiopia crowd worships",
            "src": "/static/6be07929ed35775adeb6cc55141e141c/e5166/april_ethiopia_crowd_happy.jpg",
            "srcSet": ["/static/6be07929ed35775adeb6cc55141e141c/f93b5/april_ethiopia_crowd_happy.jpg 300w", "/static/6be07929ed35775adeb6cc55141e141c/b4294/april_ethiopia_crowd_happy.jpg 600w", "/static/6be07929ed35775adeb6cc55141e141c/e5166/april_ethiopia_crowd_happy.jpg 1200w", "/static/6be07929ed35775adeb6cc55141e141c/d9c39/april_ethiopia_crowd_happy.jpg 1800w", "/static/6be07929ed35775adeb6cc55141e141c/dcec8/april_ethiopia_crowd_happy.jpg 1934w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div">{`
      `}<strong parentName="figcaption">{`ON BOTH DAYS, `}</strong>{`the crowd worshiped with great joy and
      energy. Many were singing and praising God even before the outreach began.
    `}</figcaption>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     My friend Skip Heitzig was with us in Ethiopia, and before the Crusade
    was held, he
    `}<strong parentName="div">{`
      shared encouragement and training during the Preach the Word conference to
      help pastors in their ministry.
    `}</strong>{`
    More than 1,200 attended this powerful time of Biblical teaching, worship,
    prayer, and fellowship.
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "image-float-left"
      }}>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "492px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBAv/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAFsaTXCQ//EABsQAAIDAAMAAAAAAAAAAAAAAAECAAMREiEi/9oACAEBAAEFAmbB3nNpWdY+axawn//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABsQAAIDAAMAAAAAAAAAAAAAAAABAhEhMkFS/9oACAEBAAY/AuQ5XhjY4vor0Y2f/8QAHRAAAgIBBQAAAAAAAAAAAAAAAREAIZFBUXGx0f/aAAgBAQABPyHqKCm5NJcF7uPIkFWihQFcQCgODP/aAAwDAQACAAMAAAAQzA//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREx/9oACAEDAQE/EGppD//EABcRAAMBAAAAAAAAAAAAAAAAAAABEVH/2gAIAQIBAT8QiK9P/8QAHBABAAMBAQADAAAAAAAAAAAAAREhMQBRQXHR/9oACAEBAAE/EJzItJwa7UVyFIhiGQzb9vnAgON4h85ZxEAo3P3oPJMsWpM6ER1+R9a7/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "april lady with bible",
            "title": "april lady with bible",
            "src": "/static/6f4f8232604d12dc0294772296c462c4/4b6a6/april_lady_with_bible.jpg",
            "srcSet": ["/static/6f4f8232604d12dc0294772296c462c4/f93b5/april_lady_with_bible.jpg 300w", "/static/6f4f8232604d12dc0294772296c462c4/4b6a6/april_lady_with_bible.jpg 492w"],
            "sizes": "(max-width: 492px) 100vw, 492px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     On the first day of the outreach, I told the crowd that without God,
    we are spiritually poor and blind—but He offers new life when we place our
    trust in
    `}<em parentName="div">{`
      “Jesus Christ, who gave Himself for us, that He might redeem us from every
      lawless deed and purify for Himself His own”
    `}</em>{`
    `}<nobr parentName="div">{`(Titus 2:13–14, NKJV)`}</nobr>{`.
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     
    `}<strong parentName="div">{`Ayana* was among those who surrendered her life to Him.`}</strong>{` The
    20-year-old wanted to ride a bus to the event but missed it, so she took a
    taxi with her older sister instead. When I gave the invitation, she
    responded by receiving Jesus Christ in repentance and faith. Long before the
    Crusade,
    `}<strong parentName="div">{`
      she had been interested in the Gospel—but her parents were against it.
    `}</strong>{`
    When Ayana’s father said she could attend on Saturday, she was thrilled.
    `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
      `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "614px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAb51qzNYyB3/AP/EABoQAAICAwAAAAAAAAAAAAAAAAECAAMREjH/2gAIAQEAAQUCNpi3OsW3ITgbZiSs/8QAFREBAQAAAAAAAAAAAAAAAAAAABL/2gAIAQMBAT8BS//EABcRAAMBAAAAAAAAAAAAAAAAAAABERL/2gAIAQIBAT8BV3CH/8QAHBAAAgEFAQAAAAAAAAAAAAAAAAEhAhARMUFh/9oACAEBAAY/AqXoXYzaeMb8IZ//xAAaEAEBAQEBAQEAAAAAAAAAAAABEQAhMUFR/9oACAEBAAE/IWRoeGlHikPpkozOo7Ie5AuW29+5+KH5v//aAAwDAQACAAMAAAAQ5/8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EFCNG3//xAAZEQACAwEAAAAAAAAAAAAAAAAAAREhMfH/2gAIAQIBAT8QaznRtOn/xAAeEAEAAwACAgMAAAAAAAAAAAABABEhMUFRgZGhwf/aAAgBAQABPxC0KNQ45e/H3Nk0i3q8rMu9yvEpAtAtulPqDOZXkCtdVArqWBQOvyf/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "april men with bibles",
              "title": "april men with bibles",
              "src": "/static/c0446a70db6798b6b107dc4ef64da26b/9453e/april_men_with_bibles.jpg",
              "srcSet": ["/static/c0446a70db6798b6b107dc4ef64da26b/f93b5/april_men_with_bibles.jpg 300w", "/static/c0446a70db6798b6b107dc4ef64da26b/b4294/april_men_with_bibles.jpg 600w", "/static/c0446a70db6798b6b107dc4ef64da26b/9453e/april_men_with_bibles.jpg 614w"],
              "sizes": "(max-width: 614px) 100vw, 614px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}</div>{`
    She shared that she had struggled with anxiety but now was filled with joy at
    giving her burdens to the Lord. Before she left, we gave her a copy of the
    New Testament and discipleship materials to help her grow closer to the
    Lord.
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     The Holy Spirit also worked powerfully in Bilen.* The 17-year-old is 
    living with relatives who are all believers, but she was not. She joined 
    them at the event and followed along carefully with the Gospel message I 
    shared. `}<strong parentName="div">{`At the end of the service, she boldly walked forward and prayed to
     receive Jesus Christ in repentance and faith.`}</strong>{` She told us how happy she is 
     now to follow the Lord.
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "image-float-left"
      }}>{`
      `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "614px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAABA//aAAwDAQACEAMQAAABdcl12ETN/8QAGhAAAwEAAwAAAAAAAAAAAAAAAQIDABIiMv/aAAgBAQABBQJiFeRD5PLMTSbcdY9//8QAFxEBAAMAAAAAAAAAAAAAAAAAARASIf/aAAgBAwEBPwGwZH//xAAYEQADAQEAAAAAAAAAAAAAAAAAARESUf/aAAgBAgEBPwGPNMPp/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQESFBsf/aAAgBAQAGPwKkyuynWzB//8QAGxABAAIDAQEAAAAAAAAAAAAAAQAhETFBUXH/2gAIAQEAAT8hWZtaIBlyx6lcCvYhFrPJ0ioXqKXJ8Z//2gAMAwEAAgADAAAAEPMv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhEf/aAAgBAwEBPxBUFTUf/8QAGREAAgMBAAAAAAAAAAAAAAAAABEBIZHw/9oACAECAQE/EHIG3HNP/8QAHRABAQACAgMBAAAAAAAAAAAAAREAITFBcYGh8P/aAAgBAQABPxBqArXvihcUd4Squp7MmgQ8IVdv1zoEE0P28AhVoKeOMIaWQ3XP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "april ethiopia ladies",
            "title": "april ethiopia ladies",
            "src": "/static/48db85e621950cca0d0d61723c1c20e6/9453e/april_ethiopia_ladies.jpg",
            "srcSet": ["/static/48db85e621950cca0d0d61723c1c20e6/f93b5/april_ethiopia_ladies.jpg 300w", "/static/48db85e621950cca0d0d61723c1c20e6/b4294/april_ethiopia_ladies.jpg 600w", "/static/48db85e621950cca0d0d61723c1c20e6/9453e/april_ethiopia_ladies.jpg 614w"],
            "sizes": "(max-width: 614px) 100vw, 614px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     On Sunday, a crowd stretched as far as I could see in Meskel Square—which 
    translates to “The Cross” in Amharic—and thousands responded to the Gospel by 
    publicly repenting of their sin and declaring their faith in Jesus Christ. 
    The event was a major encouragement to local believers. 
    `}<strong parentName="div">{`Nagsi* took a 
    2 1/2-hour bus ride to get to Addis Ababa.`}</strong>{` The young man shared that 
    he has only been a Christian for five years—and the Crusade reignited his passion 
    for evangelism. `}<strong parentName="div">{`“My dream is to tell people about Jesus,”`}</strong>{` he said.
     He told us he also listens to my father’s old sermons to help deepen his faith.
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     Please pray for our new brothers and sisters in Ethiopia. Ask God to strengthen
     these men and women, and make them bold witnesses for Him.
  `}</div>{`
  `}<br parentName="div"></br>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "rrt"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`THEY SAID IT WAS LIKE “HELL ON EARTH”`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`New Easter program from the California fire zones`}</h4>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     This year I recorded a special Easter message from California, where wildfires 
    left historic devastation. When I was on-site talking with residents who had survived
     the flames, many described it as “Hell on earth.” God has given this opportunity to 
     issue a clear warning about the reality of a literal Hell, which isn’t talked about 
     as much today. The Bible tells us that Hell is a `}<em parentName="div">{`“lake which burns with fire and
      brimstone”`}</em>{` `}<nobr parentName="div">{`(Revelation 21:8, NKJV)`}</nobr>{`—a place of `}<em parentName="div">{`“everlasting punishment”`}</em>{` 
      `}<nobr parentName="div">{`(Matthew 25:46, NKJV)`}</nobr>{` where `}<em parentName="div">{`“there will be weeping and gnashing of teeth”`}</em>{` `}<nobr parentName="div">{`(Matthew 8:12, NKJV)`}</nobr>{`.
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "image-float-right"
      }}>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIAA//aAAwDAQACEAMQAAABi4sMJQOn/8QAGxAAAgMAAwAAAAAAAAAAAAAAAQIDERIAEyL/2gAIAQEAAQUCgO17V48lmEDMtadvf//EABcRAAMBAAAAAAAAAAAAAAAAAAABAhL/2gAIAQMBAT8BzApR/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEC/9oACAECAQE/AbpX/8QAGhAAAwEAAwAAAAAAAAAAAAAAAAERIRJBYf/aAAgBAQAGPwLScWWQb8Ep2PD/xAAaEAACAwEBAAAAAAAAAAAAAAABEQAhMUHw/9oACAEBAAE/IXQRhadg0hD7MVYYblbWBgezuIWIDn//2gAMAwEAAgADAAAAEAsf/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERIUH/2gAIAQMBAT8QkwQVH//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QTl70v//EABwQAQEAAgMBAQAAAAAAAAAAAAERACExQVHR4f/aAAgBAQABPxDV+arUw+Y9dYBiG4vNwiAaHIPa53ggT2fuLgwY1cGG0w86z//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "april charity singing",
            "title": "april charity singing",
            "src": "/static/e0bcf307518743433f55be0003cc8fef/e5166/april_charity_singing.jpg",
            "srcSet": ["/static/e0bcf307518743433f55be0003cc8fef/f93b5/april_charity_singing.jpg 300w", "/static/e0bcf307518743433f55be0003cc8fef/b4294/april_charity_singing.jpg 600w", "/static/e0bcf307518743433f55be0003cc8fef/e5166/april_charity_singing.jpg 1200w", "/static/e0bcf307518743433f55be0003cc8fef/644c5/april_charity_singing.jpg 1440w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div" {...{
          "className": "category"
        }}>{`
      Charity Gayle and Michael W. Smith
    `}</figcaption>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     Despite what our culture says, the most loving thing we can do as believers is 
    alert others to this truth and tell them about the promise of salvation and eternal 
    life for all who trust Jesus Christ. The program is called `}<em parentName="div">{`Easter from the Ashes`}</em>{`. 
    Rupert and Lachlan Murdoch have once again so generously provided free airtime for it
     to run across the nation at noon (EDT) on Fox News, April 20, Easter Sunday. It will 
     also be on many other channels—and online—later that day.
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     Michael W. Smith and Charity Gayle join me for this special program and share 
    powerful musical performances that you won’t want to miss. Please ask God to draw 
    thousands of people to Himself through the broadcast. `}<strong parentName="div">{`Will you also plan to 
    tune in with your loved ones—especially those who don’t know the Lord?`}</strong>{` You 
    can find local listings or see a trailer online at `}<a parentName="div" {...{
          "href": "https://billygraham.org/ashes",
          "target": "_blank"
        }}><strong parentName="a">{`BillyGraham.org/ashes`}</strong></a>{`.
  `}</div>{`
  `}<br parentName="div"></br>{`
  `}<h3 parentName="div" {...{
        "className": "specialstyling mt-6",
        "id": "rrt"
      }}>{`
    `}<span parentName="h3" {...{
          "className": "category"
        }}>{`CONFRONTING EUROPE’S DARKNESS WITH THE LIGHT OF CHRIST`}</span>{`
  `}</h3>{`
  `}<h4 parentName="div">{`European Congress on Evangelism`}</h4>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     Europe once burned brightly for the cause of Jesus Christ, sending missionaries 
    and the Gospel to the far corners of the world. But sadly today, secularism has plunged
     much of the continent into spiritual darkness—and only 2% of its estimated 750 million 
     people consider themselves evangelical believers. Next month, we are convening the 
     European Congress on Evangelism in Berlin, Germany, to equip and encourage over 1,000
      pastors, leaders, teachers, missionaries, and evangelists in the work of fulfilling 
      the Great Commission. The theme is, `}<em parentName="div">{`“I am not ashamed of the gospel of Christ ...”`}</em>{` 
      `}<nobr parentName="div">{`(Romans 1:16, NKJV)`}</nobr>{`. `}<strong parentName="div">{`This is a tremendous undertaking, but it’s so critical—millions 
      of people need the hope of Jesus Christ.`}</strong>{` Will you consider a gift to help 
      shine the light of the Gospel in Europe? For $950, you can sponsor one leader—or you
       can share in the cost of equipping a faithful servant through your gift of any amount.
       Pray that those who attend will be rejuvenated in their ministries and emboldened to 
       fearlessly proclaim the only Name that has the power to save.
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     Will you also pray for the upcoming Gospel outreach in `}<strong parentName="div">{`Cochabamba, Bolivia,`}</strong>{` 
    where my son Will is preaching on April 5? There are many people in this city—and across this 
    region in the Andes Mountains—who consider themselves religious, but they don’t know Jesus 
    Christ personally. These men and women need to hear the truth of God’s Word.
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "paragraph-content"
      }}>{`
     `}<strong parentName="div">{`I am so grateful for the partnership of friends like you.`}</strong>{` You are 
    vital to the eternally important work of proclaiming the Gospel.
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "sigContent"
      }}>{`
    `}<p parentName="div">{`May God richly bless you,`}</p>{`
    `}<img parentName="div" {...{
          "className": "signature",
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2025/03/FG-Signature_09_2015_v3-1.jpg",
          "alt": ""
        }}></img>{`
    `}<p parentName="div">{`Franklin Graham`}</p>{`
    `}<p parentName="div">{`President & CEO`}</p>{`
    `}<p parentName="div">{`*Names and photos changed to protect privacy`}</p>{`
  `}</div>{`
  `}<hr parentName="div"></hr>{`
  `}<div parentName="div" {...{
        "className": "give-background",
        "id": "april-give"
      }}>{`
    `}<h4 parentName="div" {...{
          "className": "cta projects"
        }}>{`
      `}<span parentName="h4">{`YOU`}</span>{` CAN MAKE A DIFFERENCE
    `}</h4>{`
    `}<h4 parentName="div" {...{
          "className": "mb-6 smaller"
        }}>{`
      GOD WORKS THROUGH PEOPLE LIKE YOU TO CHANGE THE WORLD BY THE POWER OF THE
      GOSPEL.
    `}</h4>{`
    `}<p parentName="div">{`
      `}<span parentName="p" {...{
            "className": "options"
          }}>{`WHERE MOST NEEDED`}</span>{` `}<strong parentName="p">{`|`}</strong>{` The Gospel changes
      lives—now and for eternity. When you designate a gift for
      `}<strong parentName="p">{`“Where Most Needed,”`}</strong>{` it is available for immediate
      ministry opportunities and needs, including the personnel, resources,
      materials, equipment, and support services vital to the work of evangelism
      around the world.
    `}</p>{`
    `}<p parentName="div">{`
      `}<span parentName="p" {...{
            "className": "options"
          }}>{`EVANGELISTIC CRUSADES`}</span>{` `}<strong parentName="p">{`|`}</strong>{` So many people
      are living without the hope that comes from Jesus Christ. When you send a
      gift for `}<strong parentName="p">{`Evangelistic Crusades`}</strong>{`, you give people across 
      the globe the chance to hear the Gospel at events like the upcoming Will 
      Graham Celebration in Cochabamba, Bolivia.
    `}</p>{`
    `}<p parentName="div">{`
      `}<span parentName="p" {...{
            "className": "options"
          }}>{`TV & RADIO BROADCASTS`}</span>{` `}<strong parentName="p">{`|`}</strong>{` Your gift now 
      helps broadcast the Good News of Jesus Christ across our nation through
      powerful evangelistic `}<strong parentName="p">{`TV & Radio Broadcasts.`}</strong>{`
    `}</p>{`
    `}<p parentName="div">{`
      `}<span parentName="p" {...{
            "className": "options"
          }}>{`EUROPEAN CONGRESS ON EVANGELISM`}</span>{` `}<strong parentName="p">{`|`}</strong>{` You 
      can help equip Christian leaders in Europe to boldly proclaim the Gospel. In 
      May, the Billy Graham Evangelistic Association is convening the 
      `}<strong parentName="p">{`European Congress on Evangelism`}</strong>{` (ECE) in Berlin to encourage 
      believers from 57 countries and territories in sharing God’s truth in their 
      communities. Your gift will help send these leaders to the ECE and reach 
      people all over Europe with the Good News.
    `}</p>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      