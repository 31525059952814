import React from 'react';

const donationHeaderChurchFinder = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great News {firstName}! “The Importance of a Church Home” is on its way to your inbox.
    </h2>
    <br></br>
    <div>
      <p>We praise the Lord for your heart to learn more about finding a church near you. The church is not just a building—but the people of God who are called to <em>“meet together… encouraging one another”</em> (Hebrews 10:25, ESV).</p>
      <p><b>If the Lord leads you, would you consider a donation today?</b></p>
      <p>The Billy Graham Evangelistic Association is able to offer the hope of Jesus Christ through materials like this because of friends like you. The Lord is using the gifts and prayers of other believers to reach people across the globe with His love—and there are so many more who need to hear the Good News.</p>
      <p>Will you give them the opportunity to hear it?</p>
      <p>Your gift today will help share the Gospel with people around the world who are far from God and equip them with powerful Biblical resources to become faithful followers of Christ.</p>
    </div>
  </div>
);

export default donationHeaderChurchFinder;
