import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><h3 {...{
        "className": "specialstyling mt-6",
        "id": "rrt"
      }}><span parentName="h3" {...{
          "className": "category"
        }}>{`BILLY GRAHAM RAPID RESPONSE TEAM`}</span></h3>{`
    `}<h4>{`FINDING FAITH IN THE FIRE`}</h4>{`
    `}<div {...{
        "className": "image-float-left"
      }}>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAcuR2xukwzr/xAAcEAABBAMBAAAAAAAAAAAAAAABAAIDExESIUL/2gAIAQEAAQUCjl6JbGAL3nRtpX//xAAWEQEBAQAAAAAAAAAAAAAAAAAAASH/2gAIAQMBAT8BjH//xAAWEQEBAQAAAAAAAAAAAAAAAAAAAiL/2gAIAQIBAT8Bpp//xAAdEAACAgEFAAAAAAAAAAAAAAAAAQIhEBEiMUFR/9oACAEBAAY/AuWbVZaY9PSTXcsf/8QAGxABAAIDAQEAAAAAAAAAAAAAAQARITFBcaH/2gAIAQEAAT8hW63dGXmUNJArAfLmmVQdpSXbPs//2gAMAwEAAgADAAAAEPAv/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAwEBPxAD2yf/xAAYEQEBAAMAAAAAAAAAAAAAAAABABEhUf/aAAgBAgEBPxBIabPV/8QAHRABAQABBAMAAAAAAAAAAAAAAREAITFBUZGhwf/aAAgBAQABPxA8HrFoHMOz3lVqRNr3gewJ9sKsOAnnHtxRrZgaqvLef//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "RRT chaplains are praying with locals in LA",
            "title": "RRT chaplains are praying with locals in LA",
            "src": "/static/19f82dcf70ce27a3a5dda48f00969c82/6a068/rrt-la-chaplains.jpg",
            "srcSet": ["/static/19f82dcf70ce27a3a5dda48f00969c82/f93b5/rrt-la-chaplains.jpg 300w", "/static/19f82dcf70ce27a3a5dda48f00969c82/b4294/rrt-la-chaplains.jpg 600w", "/static/19f82dcf70ce27a3a5dda48f00969c82/6a068/rrt-la-chaplains.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="div">{`OUR TRAINED CHAPLAINS pray with residents in California following the deadly wildfires that destroyed entire communities and claimed dozens of lives.`}</figcaption>{`
    `}</div>{`
    `}<p>{`
        Even as we’re seeing incredible things around the world, we also know that `}<strong parentName="p">{`many in our own nation are enduring some of their darkest days.`}</strong>{` Crisis-trained Billy Graham Rapid Response Team chaplains are still ministering in Southern California, comforting those who were displaced, including many who lost everything.
    `}</p>{`
    `}<p>{`
        They happened to meet Carla* as she waited in a food distribution line in Los Angeles. Carla only spoke Spanish, but—as God directed it—the chaplain was bilingual and spoke fluent Spanish. Early in the conversation, Carla’s hurt was evident, and she did not hesitate to share her disdain for churches. The chaplain graciously listened to Carla’s grievances and then lovingly shared that while people fail, Jesus never will. During their lengthy conversation, `}<strong parentName="p">{`the chaplain had the chance to share the hope of Christ, and the Holy Spirit softened Carla’s heart.`}</strong>{` There, in a line for food, Carla received `}<em parentName="p">{`“the bread of life”`}</em>{` (John 6:35, NKJV).
    `}</p>{`
    `}<div {...{
        "className": "image-float-right"
      }}>{`
    `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUCAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABvkq2o3EIX//EABwQAAICAgMAAAAAAAAAAAAAAAIDARIABBEhMv/aAAgBAQABBQKGppz1UcZ5Ydk7ckL/AP/EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPwGYj//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/Aar/xAAbEAACAgMBAAAAAAAAAAAAAAAAEQESAiFxMf/aAAgBAQAGPwJy2RNK9PDJaqLhKyk//8QAGRABAAMBAQAAAAAAAAAAAAAAAQARMSFB/9oACAEBAAE/IXUktqcNIvqvJT4gKDZ6e2xTZjbu9hkAQavJ/9oADAMBAAIAAwAAABD4/wD/xAAYEQADAQEAAAAAAAAAAAAAAAAAAREhUf/aAAgBAwEBPxDatIjh/8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAUf/aAAgBAgEBPxAUg6u7/8QAHhABAAICAQUAAAAAAAAAAAAAARExACFBUWGBscH/2gAIAQEAAT8QIAYQkWdTfUyM5RVlqdzHvH7BzVxipzSuAjFcG9ZRgRXnAEEAg0q+2f/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "RRT chaplains and Franklin Graham are praying with locals in LA",
            "title": "RRT chaplains and Franklin Graham are praying with locals in LA",
            "src": "/static/485782fdefbf6756b4109fb80a0921a0/6a068/rrt-la-pray.jpg",
            "srcSet": ["/static/485782fdefbf6756b4109fb80a0921a0/f93b5/rrt-la-pray.jpg 300w", "/static/485782fdefbf6756b4109fb80a0921a0/b4294/rrt-la-pray.jpg 600w", "/static/485782fdefbf6756b4109fb80a0921a0/6a068/rrt-la-pray.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}</div></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      