import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "className": "content"
    }}>{`
 `}<div parentName="div" {...{
        "className": ""
      }}>{`
      `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABasxhWCSWf//EABoQAQACAwEAAAAAAAAAAAAAAAIBEQADEhP/2gAIAQEAAQUCuTidKPOlr5CWRumv/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BR//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABwQAAEDBQAAAAAAAAAAAAAAAAABERIQITFhkf/aAAgBAQAGPwJrmjKdJSEen//EABwQAQACAQUAAAAAAAAAAAAAAAEAEUEhMWGhsf/aAAgBAQABPyGp7TWjhcuCwXATWGOiA5jtnc//2gAMAwEAAgADAAAAEFz/AP/EABgRAAIDAAAAAAAAAAAAAAAAAAARASFR/9oACAEDAQE/EE4oTT//xAAYEQADAQEAAAAAAAAAAAAAAAAAAREhUf/aAAgBAgEBPxCx6V8P/8QAHRABAQADAAIDAAAAAAAAAAAAAREAITFBwaGx0f/aAAgBAQABPxAAJLxgid+8Q6qlCd7+YqxJuz7xoZLEfnLFA02cwU2TXn6z/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "rrt Asheville 241109 lr 1 8849 inet",
            "title": "rrt Asheville 241109 lr 1 8849 inet",
            "src": "/static/1d63b3e2123b4978e9e5312ad87a49fd/6a068/rrt_Asheville_241109_lr_1_8849_inet.jpg",
            "srcSet": ["/static/1d63b3e2123b4978e9e5312ad87a49fd/f93b5/rrt_Asheville_241109_lr_1_8849_inet.jpg 300w", "/static/1d63b3e2123b4978e9e5312ad87a49fd/b4294/rrt_Asheville_241109_lr_1_8849_inet.jpg 600w", "/static/1d63b3e2123b4978e9e5312ad87a49fd/6a068/rrt_Asheville_241109_lr_1_8849_inet.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}</div>{`
  `}<div parentName="div" {...{
        "id": "Desperate-for-Peace",
        "className": "piece"
      }}>{`
    `}<h2 parentName="div">{`Desperate for Peace`}</h2>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling"
        }}>{`
      `}<span parentName="h3" {...{
            "className": "city"
          }}>{`NORTH CAROLINA`}</span>{`
    `}</h3>{`
      Karissa’s* home in Buncombe County, North Carolina, was hit by Hurricane Helene. Samaritan’s Purse relief workers deployed to her neighborhood to help with cleanup and rebuilding. She was so thankful when they introduced her to our chaplains. They chatted for a while about the storm and how it changed her life. Karissa didn’t attend church and had no religious affiliation whatsoever—but she was eager for peace. `}<strong parentName="div">{`She followed along as the chaplains told her about the peace found only through a relationship with Jesus Christ: `}</strong>{` “`}<em parentName="div">{`In him all the fullness of God was pleased to dwell, and through him to reconcile to himself all things, whether on earth or in heaven, making peace by the blood of his cross`}</em>{`” (Colossians 1:19–20, ESV). She prayed with excitement and joy to receive Him in repentance and faith. Afterward, Karissa told the chaplains she felt like a burden had been lifted.
  `}</div>{`
   `}<hr parentName="div" {...{
        "className": "divider"
      }}></hr>{`
  `}<div parentName="div" {...{
        "id": "No-Barrier-for-the-Holy-Spirit",
        "className": "piece"
      }}>{`
    `}<h2 parentName="div">{`No Barrier for the Holy Spirit`}</h2>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling"
        }}>{`
      `}<span parentName="h3" {...{
            "className": "city"
          }}>{`FLORIDA`}</span>{`
    `}</h3>{`
     Our chaplains connected with Hector* near his home in Tampa, Florida. The homeowner had been hit by both hurricanes Helene and Milton. Hector didn’t speak English—but our chaplain remembered enough Spanish from his military service decades earlier to converse with him. A Samaritan’s Purse volunteer also helped translate, and the two talked about faith. Our chaplain shared the Scriptures found in our “Steps to Peace With God” booklet, and Hector was overcome with emotion. `}<strong parentName="div">{`With tears in his eyes, he prayed to surrender his life to Jesus Christ.`}</strong>{` We praise God for overcoming potential language barriers to draw Hector to Himself! Please pray that he will walk closely with Jesus Christ, who promises, “`}<em parentName="div">{`Whoever abides in me and I in him, he it is that bears much fruit, for apart from me you can do nothing`}</em>{`” (John 15:5, ESV).
  `}</div>{`
     `}<hr parentName="div" {...{
        "className": "divider"
      }}></hr>{`
  `}<div parentName="div" {...{
        "id": "Tragedy-Turned-His-Heart-Toward-God",
        "className": "piece"
      }}>{`
    `}<h2 parentName="div">{`Tragedy Turned His Heart Toward God`}</h2>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling"
        }}>{`
      `}<span parentName="h3" {...{
            "className": "city"
          }}>{`CALIFORNIA`}</span>{`
    `}</h3>{`
     Luke,* a postal worker in Southern California, had been working in the same neighborhood for 18 years—and it was completely destroyed by the Palisades wildfire. One of our chaplains spoke with him and listened compassionately as he shared his grief. He was devastated to see how the fires had obliterated the homes of the families he had served for so many years. Soon, their conversation
     `}<div parentName="div" {...{
          "className": "image-float-left"
        }}>{`
      `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEEAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABm35tCMRX/8QAGRAAAgMBAAAAAAAAAAAAAAAAAQIAERIx/9oACAEBAAEFAmZdCHNnoc0GM//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPwFbS//EABcRAAMBAAAAAAAAAAAAAAAAAAABERL/2gAIAQIBAT8BlMM//8QAGxAAAgEFAAAAAAAAAAAAAAAAAAEQISIxQZH/2gAIAQEABj8CSbZbg3wUUP/EABsQAQEBAQADAQAAAAAAAAAAAAERACExQWGx/9oACAEBAAE/IagS9jpe9A4fcW/zeTX3pvirLgHSb//aAAwDAQACAAMAAAAQDy//xAAXEQEBAQEAAAAAAAAAAAAAAAABACER/9oACAEDAQE/EEDI4bf/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREx/9oACAECAQE/EKSLWRP/xAAdEAEBAAIBBQAAAAAAAAAAAAABEQAhMUFhcYHB/9oACAEBAAE/EFK9RgG9Bl1NEqwWoXrzjKKjGwHyOCa1FvccOoV1Hu/MHgtbF23bn//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "rrt day 3 chaplain ladies ed1 8790 inet",
              "title": "rrt day 3 chaplain ladies ed1 8790 inet",
              "src": "/static/11a0d00ae65c6f4e39f11961774897f0/6a068/rrt_day_3_chaplain_ladies_ed1_8790_inet.jpg",
              "srcSet": ["/static/11a0d00ae65c6f4e39f11961774897f0/f93b5/rrt_day_3_chaplain_ladies_ed1_8790_inet.jpg 300w", "/static/11a0d00ae65c6f4e39f11961774897f0/b4294/rrt_day_3_chaplain_ladies_ed1_8790_inet.jpg 600w", "/static/11a0d00ae65c6f4e39f11961774897f0/6a068/rrt_day_3_chaplain_ladies_ed1_8790_inet.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}</div>{`
     turned to faith. Luke explained that he grew up in a religious home but never committed himself to God. The catastrophic damage and loss of life from the fires had changed his heart. Now,`}<strong parentName="div">{` he knew he needed the Lord.`}</strong>{` After following along as our chaplain read through “Steps to Peace With God,” he decided to embrace the truth that “`}<em parentName="div">{`when the goodness and loving kindness of God our Savior appeared, he saved us, not because of works done by us in righteousness, but according to his own mercy`}</em>{`” (Titus 3:4–5, ESV). Luke was eager to share that his daughter attends church, and he plans to join her there as soon as possible. 
  `}</div>{`
     `}<hr parentName="div" {...{
        "className": "divider"
      }}></hr>{`
  `}<div parentName="div" {...{
        "id": "An-Eternal-Home-in-Heaven",
        "className": "piece"
      }}>{`
    `}<h2 parentName="div">{`An Eternal Home in Heaven`}</h2>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling"
        }}>{`
      `}<span parentName="h3" {...{
            "className": "city"
          }}>{`GEORGIA`}</span>{`
    `}</h3>{`
     Chaplains visited Brad* after his home in Valdosta, Georgia, was severely damaged by Hurricane Helene. The wind had downed a tree, which cut right through his house. He was inside when
     `}<div parentName="div" {...{
          "className": "image-float-right"
        }}>{`
      `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAWAQEBAQAAAAAAAAAAAAAAAAACAQP/2gAMAwEAAhADEAAAAVPn6M0jNR//xAAbEAABBAMAAAAAAAAAAAAAAAACAAEDIRITIv/aAAgBAQABBQJ3oSWxSjxEOSko/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAAAAhH/2gAIAQMBAT8BZT//xAAYEQACAwAAAAAAAAAAAAAAAAAAAQMREv/aAAgBAgEBPwGjETP/xAAaEAACAgMAAAAAAAAAAAAAAAAAAQIREBIh/9oACAEBAAY/At8pWVLo0j//xAAbEAEBAQEBAAMAAAAAAAAAAAABEQAhQTFRsf/aAAgBAQABPyFy1v7udE75fnBKWR0fIfrAjgDEIcN//9oADAMBAAIAAwAAABAsD//EABcRAAMBAAAAAAAAAAAAAAAAAAARMWH/2gAIAQMBAT8QThqf/8QAFxEBAQEBAAAAAAAAAAAAAAAAEQEAIf/aAAgBAgEBPxA8daku/8QAHRABAQACAQUAAAAAAAAAAAAAAREAITFRYYGh4f/aAAgBAQABPxACyUseXX3hq4UCg7o4wRtJ5xEK1Ui1+YoVXXFhrAomoHcz/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "brad rrt georgia tall 230916 gg1 6516 inet",
              "title": "brad rrt georgia tall 230916 gg1 6516 inet",
              "src": "/static/60bb38513ebba6d570f5fbff9a37be54/6a068/brad_rrt_georgia_tall_230916_gg1_6516_inet.jpg",
              "srcSet": ["/static/60bb38513ebba6d570f5fbff9a37be54/f93b5/brad_rrt_georgia_tall_230916_gg1_6516_inet.jpg 300w", "/static/60bb38513ebba6d570f5fbff9a37be54/b4294/brad_rrt_georgia_tall_230916_gg1_6516_inet.jpg 600w", "/static/60bb38513ebba6d570f5fbff9a37be54/6a068/brad_rrt_georgia_tall_230916_gg1_6516_inet.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
    `}</div>{`
     it happened, and the experience had shaken him and made him think about eternity. Our chaplains asked Brad about Heaven, and he said he hoped to make it there. `}<strong parentName="div">{`He listened with an open heart as they shared the Good News that for those who trust in Jesus Christ`}</strong>{`, “`}<em parentName="div">{`if the tent that is our earthly home is destroyed, we have a building from God, a house not made with hands, eternal in the heavens`}</em>{`” (2 Corinthians 5:1, ESV). Brad was eager to respond—he wanted to fully surrender his life to Jesus Christ. With the chaplains by his side, he prayed to accept Christ as Savior and follow Him as Lord. We praise God for this new brother!
     `}<br parentName="div"></br>{`
   `}<div parentName="div" {...{
          "className": "image-nocaption"
        }}>{`
        `}<span parentName="div" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "960px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIFA//EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAFEr4ByRyn/xAAZEAACAwEAAAAAAAAAAAAAAAABAgADERL/2gAIAQEAAQUCsTJxGqPWAw1jpq8b/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAABEBITEyQf/aAAgBAQAGPwJTkTs6XBRsf//EABwQAQACAgMBAAAAAAAAAAAAAAEAESFBMWFxsf/aAAgBAQABPyECcrcSFFcwMtviaI+kyWFldRkLT//aAAwDAQACAAMAAAAQkD//xAAWEQADAAAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QiIz/xAAWEQEBAQAAAAAAAAAAAAAAAAAAESH/2gAIAQIBAT8QrH//xAAcEAEBAAMAAwEAAAAAAAAAAAABEQAhMUFRYfD/2gAIAQEAAT8Q2qcAD+5kHSET47F1iInXh8vv7jNq+QZhlRqi1HZMBSVuy5//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "250211 lr1 5458 inet",
              "title": "250211 lr1 5458 inet",
              "src": "/static/787408a4f9397521c3c87d28f77cd530/6a068/250211_lr1_5458_inet.jpg",
              "srcSet": ["/static/787408a4f9397521c3c87d28f77cd530/f93b5/250211_lr1_5458_inet.jpg 300w", "/static/787408a4f9397521c3c87d28f77cd530/b4294/250211_lr1_5458_inet.jpg 600w", "/static/787408a4f9397521c3c87d28f77cd530/6a068/250211_lr1_5458_inet.jpg 960w"],
              "sizes": "(max-width: 960px) 100vw, 960px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span>{`
      `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "id": "He-Found-What-His-Heart-Was-Longing-For",
        "className": "piece"
      }}>{`
    `}<h2 parentName="div">{`He Found What His Heart Was Longing For`}</h2>{`
    `}<h3 parentName="div" {...{
          "className": "specialstyling"
        }}>{`
      `}<span parentName="h3" {...{
            "className": "city"
          }}>{`KENTUCKY`}</span>{`
    `}</h3>{`
       Aiden* met a couple of chaplains at our Mobile Ministry Center in Pikeville, Kentucky. Devastating floods had hit his neighborhood, and he was hoping to find a generator and a heater. The young man was with Grant,* his girlfriend’s dad. After making sure Aiden knew how to find the physical resources, our chaplains turned the conversation to “`}<em parentName="div">{`the kingdom of God and the name of Jesus Christ`}</em>{`” (Acts 8:12, ESV). Grant said he was a believer, but Aiden shared that he had never made a decision to follow the Lord. `}<strong parentName="div">{`He listened to the chaplains share the Gospel with an open heart—and then prayed to surrender his life to Jesus Christ! `}</strong>{` We give God all the glory for this changed life! Will you join us in praying for our new brother and his family as they recover from the flooding?
      `}<br parentName="div"></br>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "id": "learn-how",
        "className": "piece"
      }}>{`
    `}<h4 parentName="div">{`
      You can bring the love and hope of Jesus Christ to people enduring
      tragedy.
    `}</h4>{`
    `}<div parentName="div">{`
      `}<h5 parentName="div">{`PRAY`}</h5>{`
      `}<p parentName="div">{`
        Please pray for `}<strong parentName="p">{`people across our nation `}</strong>{`and around the
        world who are suffering in the wake of natural and man-made disasters.
        Will you ask God to give
        `}<strong parentName="p">{` BG-RRT chaplains strength and wisdom`}</strong>{` as they minister
        in Jesus’ Name to these men and women? Please also
        `}<strong parentName="p">{`
          lift up in prayer the `}<em parentName="strong">{`Sharing Hope in Crisis`}</em>{` trainings
        `}</strong>{`
        taking place across the globe—and online.
      `}</p>{`
    `}</div>{`
    `}<div parentName="div">{`
      `}<h5 parentName="div">{`GIVE`}</h5>{`
      `}<p parentName="div">{`
        Your gift to the `}<strong parentName="p">{`Billy Graham Rapid Response Team`}</strong>{` helps
        comfort the hurting with the hope of Christ by training and deploying
        chaplains into crisis situations throughout the U.S. and around the
        world.
      `}</p>{`
    `}</div>{`
  `}</div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      