import React from 'react';
import { Block } from '@bgea-js/react-ui';

const privacyPolicyba = () => (
  <div>
    <Block textSize={6}>
        *Al proporcionar sus datos personales, usted confirma que desea recibir mensajes ocasionales de BGEA. Puede cancelar en cualquier momento. Para gestionar sus preferencias y obtener más información sobre cómo tratamos sus datos, consulte la  <a href="https://billygraham.org/es/politica-de-privacidad">Política de privacidad de BGEA</a>.
    </Block>
  </div>
);

export default privacyPolicyba;
