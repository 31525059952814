import React from 'react';

const donationHymnForTheSoul = ({ firstName }) => (
  <div>
    <h2
      style={{
        fontSize: '40px',
        textAlign: 'center',
        color: '#4a4a4a',
      }}
    >
      Great news {firstName}! Your devotional is on its way to your inbox.
    </h2>
    <br></br>
    <div>
      <p>
        We praise God for your heart to worship the Lord with music. It’s our
        prayer that this resource will help you “[sing]{' '}
        <em>...to the Lord with your heart</em>” (Ephesians 5:19, ESV).
      </p>
      <p>
        <b>If the Lord leads you, would you consider a donation today?</b>
      </p>
      <p>
        The Billy Graham Evangelistic Association is able to offer the hope of
        Jesus Christ through materials like this because of friends like you.
        The Lord is using the gifts and prayers of other believers to reach
        people across the globe with the Gospel—and there are so many more who
        need to hear the Good News of Jesus.
      </p>
      <blockquote
        style={{
          borderLeft: '1px solid #6e6e6e',
          borderRight: '1px solid #6e6e6e',
          textAlign: 'center',
          marginBottom: 0,
          color: '#6e6e6e',
          padding: '9px 19px',
        }}
      >
        Will you give them the opportunity to hear it? Your gift today will help
        share God’s love with people around the world and equip them with
        powerful Biblical resources.
      </blockquote>
    </div>
  </div>
);

export default donationHymnForTheSoul;
