import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><h4>{`GOSPEL IMPACT AROUND THE GLOBE`}</h4>
      <p>{`
   My friends, God is indeed on the move. From `}<strong parentName="p">{`Germany`}</strong>{` to `}<strong parentName="p">{`Arizona`}</strong>{` to `}<strong parentName="p">{`Estonia`}</strong>{`, the Gospel is going forward, people are being equipped to share the hope of Jesus, and souls are being saved for eternity. `}<strong parentName="p">{`Please know how much we appreciate your partnership in this mission.`}</strong>{` You are an incredible blessing to us and to so many who are desperately seeking the Savior.
`}</p>
      <div>{`
    `}<p parentName="div">{`Blessings,`}</p>{`
    `}<img parentName="div" {...{
          "className": "signature",
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2021/02/Will-Graham-Signature-2.png.webp",
          "alt": ""
        }}></img>{`
    `}<p parentName="div">{`Will Graham`}</p>{`
    `}<p parentName="div">{`Executive Vice President`}</p>
      </div></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      