import React from 'react';
import { Block } from '@bgea-js/react-ui';

const customConfirmationWGE = ({
  showRecurrenceText,
  recurrenceText,
  transactionId,
  total,
  firstName,
}) => (
  <div>
    <h1 className="conf-title"> Great News {firstName}!</h1>
    <Block>
      Thank you for your gift to help share the Gospel of Jesus Christ with
      others. Your copy of “The Suffering of the King” should arrive in 2–4
      weeks. We praise God for your heart to grow in faith this Easter season.
      May you and your family celebrate with joy the Biblical truth that <em>“He has risen”</em> (Mark 16:6, ESV).
    </Block>
    <Block>
      The confirmation number for your{' '}
      {showRecurrenceText && <strong>{recurrenceText}</strong>} donation is{' '}
      <strong>{transactionId}</strong> for the amount of $
      {total.toFixed(2) ?? 'NEED AMOUNT'}.
    </Block>
    <Block>
      If you have any questions, call us at 1-877-247-2426. Please have your
      confirmation number ready and indicate that you are contacting us about a
      donation made through the Billy Graham Evangelistic Association website.
    </Block>
    <Block>
      To keep you updated on the ministry, we would like to send you our prayer
      letter, <i>From the Desk of Franklin Graham</i>, featuring biblical truths
      about current events, news of his travels and updates on the work of BGEA
      with thousands of people around the world. You can opt-out from receiving
      these emails anytime.
    </Block>
  </div>
);

export default customConfirmationWGE;
