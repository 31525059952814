import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <undefined><div>{`
    `}<p parentName="div">{`Dear Friend,`}</p>{`
    `}<p parentName="div">{`  Between the end of 2024 and the beginning of this year, `}<strong parentName="p">{`so many people from coast to coast have suffered devastation. `}</strong>{` Cleanup efforts are still underway from hurricanes Helene and Milton all across the Southeast, while thousands in California are picking up the pieces after wildfires destroyed entire communities. As I write, many in Kentucky and West Virginia are recovering from historic flooding.
    `}</p>{`
    `}<p parentName="div">{`  You are helping reach these men, women, and children with the Lord’s unshakable promise for all who trust in Him: “`}<em parentName="p">{`The peace of God, which surpasses all understanding, will guard your hearts and your minds in Christ Jesus`}</em>{`” (Philippians 4:7, ESV).`}</p>{`
    `}<p parentName="div">{`  I am deeply grateful for your commitment to sharing His love with hurting people through the Billy Graham Rapid Response Team (BG-RRT). Throughout these deployments—and many others—crisis-trained chaplains share the Gospel, and hundreds of individuals have reported decisions for Jesus Christ.  `}<strong parentName="p">{`This life-changing work would not be possible without you. `}</strong>{` Please keep these men and women in your prayers, and ask God to strengthen our chaplains as they serve Him.`}</p>{`
    `}<p parentName="div">{`May God richly bless you,`}</p>{`
    `}<img parentName="div" {...{
          "className": "signature",
          "src": "https://static.billygraham.org/sites/lp.billygraham.org/uploads/prod/2023/01/FG-signature-VectorLineArt.png",
          "alt": ""
        }}></img>{`
    `}<p parentName="div">{`Franklin Graham | President & CEO`}</p>{`
     `}<p parentName="div">{`*Names and photos changed to protect privacy`}</p>
      </div>
      <div {...{
        "className": "image-nocaption"
      }}>{`
        `}<span parentName="div" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "960px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.333333333333332%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAADABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAEDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAcewIB//xAAYEAADAQEAAAAAAAAAAAAAAAAAAQMUAv/aAAgBAQABBQJU7RosjVc//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAwEBAAAAAAAAAAAAAAAAAAIzkSH/2gAIAQEABj8C47aUbSjH/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEhMYHx/9oACAEBAAE/IU0LQ6hOkf/aAAwDAQACAAMAAAAQA8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEAAQUAAAAAAAAAAAAAAAABEQAQUWHR/9oACAEBAAE/EI+Fg7UMEPabE//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "psalm 46 1 w edge 960",
            "title": "psalm 46 1 w edge 960",
            "src": "/static/87d55af554f4c7fce5f10e5a36e1dfc5/6a068/psalm_46-1_w-edge_960.jpg",
            "srcSet": ["/static/87d55af554f4c7fce5f10e5a36e1dfc5/f93b5/psalm_46-1_w-edge_960.jpg 300w", "/static/87d55af554f4c7fce5f10e5a36e1dfc5/b4294/psalm_46-1_w-edge_960.jpg 600w", "/static/87d55af554f4c7fce5f10e5a36e1dfc5/6a068/psalm_46-1_w-edge_960.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
 `}</div></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      